





import { Component, Vue } from 'vue-property-decorator'
import { AuthActions } from '../store/auth/auth.actions'
import { AuthGetters } from '../store/auth/auth.getters'
import User from '@/shared/modules/user/user.model'
import { RoleLevel } from '@/shared/modules/role/role.model'
import { AdminRoutes } from '@/shared/router/admin'
import { CompanyRoutes } from '@/shared/router/company'
import Company from '@/shared/modules/company/company.model'
import { CompanyActions } from '@/shared/store/company/company.actions'

@Component
export default class Redirection extends Vue {
  mounted() {
    this.$store.dispatch(AuthActions.me).then(() => {
      const user: User = this.$store.getters[AuthGetters.getUser]
      const company: Company | undefined = user.company
      this.$store.dispatch(CompanyActions.setCompany, company)
      if (user.role.level === RoleLevel.superadmin) {
        this.$router.replace({ name: AdminRoutes.index }).catch(() => {
        })
        return
      }
      if ( ! company) return
      this.$router.replace({ name: CompanyRoutes.index, params: { company: company.slug } })
    })
  }
}
